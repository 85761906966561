export const handleCopy = (text, domainStatus) => {
  const elementId =
    text === domainStatus?.mentor?.cname_records?.certificate_validation?.name
      ? 'mentor-cert-name'
      : text ===
          domainStatus?.mentor?.cname_records?.certificate_validation?.value
        ? 'mentor-cert-value'
        : text === domainStatus?.mentor?.cname_records?.cloudfront?.name
          ? 'mentor-cloud-name'
          : text === domainStatus?.mentor?.cname_records?.cloudfront?.value
            ? 'mentor-cloud-value'
            : text ===
                domainStatus?.skills?.cname_records?.certificate_validation
                  ?.name
              ? 'skills-cert-name'
              : text ===
                  domainStatus?.skills?.cname_records?.certificate_validation
                    ?.value
                ? 'skills-cert-value'
                : text === domainStatus?.skills?.cname_records?.cloudfront?.name
                  ? 'skills-cloud-name'
                  : text ===
                      domainStatus?.skills?.cname_records?.cloudfront?.value
                    ? 'skills-cloud-value'
                    : text ===
                        domainStatus?.analytics?.cname_records
                          ?.certificate_validation?.name
                      ? 'analytics-cert-name'
                      : text ===
                          domainStatus?.analytics?.cname_records
                            ?.certificate_validation?.value
                        ? 'analytics-cert-value'
                        : text ===
                            domainStatus?.analytics?.cname_records?.cloudfront
                              ?.name
                          ? 'analytics-cloud-name'
                          : text ===
                              domainStatus?.analytics?.cname_records?.cloudfront
                                ?.value
                            ? 'analytics-cloud-value'
                            : null;

  const element = document.getElementById(elementId);
  if (element) {
    const originalText = element.innerText;
    element.innerText = 'Copied';
    setTimeout(() => {
      element.innerText = originalText;
    }, 2000);
  }
};
