import React, {useContext, useEffect, useState} from "react";
import "./TeamsAssignmentsListViewContent.css"
import {getTableIndex, getTableSizes, isCurrentUserOnlyDepartmentManager} from "../../../hooks/helpers";
import useLicensingGroup from "../../../hooks/useLicensingGroup";
import TableSizeDropdown from "../../../components/TableSizeDropdown/TableSizeDropdown";
import AddLicensingUserGroup from "../../../components/AddLicensingUserGroup/AddLicensingUserGroup";
import {AppDataContext, IBLSpinLoader} from "@iblai/ibl-web-react-common";
import EmptyTableDataInfographic from "../../../components/EmptyTableDataInfographic/EmptyTableDataInfographic";
import LicensingUsersInGroup from "../../../components/LicensingUsersInGroup/LicensingUsersInGroup";
import ReactPaginate from "react-paginate";
import {useTranslation} from "react-i18next";
import {Link} from "react-router-dom";
import useTeamsList from "../../../hooks/useTeamsList";
import SlTooltip from '@shoelace-style/shoelace/dist/react/tooltip';
import {deleteTeam} from "../../../hooks/utils/teams";
import useLearnersTeamsAssignmentsList from "../../../hooks/useLearnersTeamsAssignmentsList";
import dayjs from "dayjs";
import {deleteCredentialsTeamsAssignments, deleteTeamsAssignments} from "../../../hooks/utils/teamsAssignments";
import useGroupsTeamsAssignmentsList from "../../../hooks/useGroupsTeamsAssignmentsList";

const TeamsAssignmentsListViewContent = ({contentType="course"}) => {
    const { t } = useTranslation();
    const currentUserIsManager = isCurrentUserOnlyDepartmentManager()
    const TABLE_SIZES = getTableSizes();
    
    const [learnerSearch, setLearnerSearch] = useState('');
    const [learnerSize, setLearnerSize] = useState(TABLE_SIZES[0]);
    const [learnerPage, setLearnerPage] = useState(1);
    const [learnerRefresher, setLearnerRefresher] = useState(null);
    const [focusedLearnerForDeletion, setFocusedLearnerForDeletion] = useState('')

    const [groupSearch, setGroupSearch] = useState('');
    const [groupSize, setGroupSize] = useState(TABLE_SIZES[0]);
    const [groupPage, setGroupPage] = useState(1);
    const [groupRefresher, setGroupRefresher] = useState(null);
    const [focusedGroupForDeletion, setFocusedGroupForDeletion] = useState('')
    
    const {setNotification} = useContext(AppDataContext)
    const {learners, learnersLoading, learnersPagination}=  useLearnersTeamsAssignmentsList({contentType, search:learnerSearch, page:learnerPage, size:learnerSize, refresher:learnerRefresher})
    const {groups, groupsLoading, groupsPagination}=  useGroupsTeamsAssignmentsList({contentType, search:groupSearch, page:groupPage, size:groupSize, refresher:groupRefresher})

    const paginateLearners = ({ selected }) => {
        setLearnerPage(selected + 1);
    };
    const paginateGroups = ({ selected }) => {
        setGroupPage(selected + 1);
    };


    const handleAssignmentDeletion = async (info, isGroup=false) => {
        isGroup ? setFocusedGroupForDeletion(info?.id) :setFocusedLearnerForDeletion(info?.id)
        const deletedSuccessfully = contentType==="credential" ? await deleteCredentialsTeamsAssignments(info?.id) : await deleteTeamsAssignments(info?.id, isGroup)
        if(deletedSuccessfully){
            setNotification({
                msg:`${isGroup ? info?.group_name : info?.name} ${contentType} assignment deleted successfully.`,
                success:true
            })
            isGroup ? setGroupRefresher(Date.now()) : setLearnerRefresher(Date.now())
        }else{
            setNotification({
                msg:`An error occurred during the assignment deletion.`,
                success:false
            })
        }
        setFocusedLearnerForDeletion(null)
        setFocusedGroupForDeletion(null)
    }

    const getContentName = (singleAssignmentData) =>{
        switch (contentType){
            case 'program' :
                return singleAssignmentData?.program_name
            case 'pathway':
                return singleAssignmentData?.pathway_name
            case 'credential':
                return singleAssignmentData?.credential_name
            default:
                return singleAssignmentData?.course_name
        }
    }

  return (
      <>
          <div className="p-row r-r">
              <div className="div-block-204 f-w">
                  <div className="div-block-183">
                      <div className="div-block-205">
                          <div className="div-block-206"/>
                          <div className="text-block-113">Assignments | <span className={"capitalize"}>{contentType}</span>s</div>
                      </div>
                      <div className="div-block-308">
                          <Link to={`/teams/assignments/add-new/${contentType}s`} className="link-block-22 w-inline-block">
                              <div data-w-id="2e311dd0-e1c2-0ab6-f2a1-1e492e5123a6"
                                   className="div-block-207 first active follow-user alt-btn issue-popup">
                                  <div className="text-block-111">Assign {contentType}</div>
                              </div>
                          </Link>
                      </div>
                  </div>
                  <div react-component="CourseTable" className="table_cont-2">
                      <div className="div-block-217">
                          <div className="div-block-169">
                              <div>
                                  <div prop-data-value-tablename="Courses" prop-data-names="tableName"
                                       className="text-block-135">Learners Assignments<br/></div>
                              </div>
                          </div>
                          <div className="div-block-169">
                              <div className="div-block-171">
                                  <div className="sm">Show</div>
                                  <div className="form-block w-form">
                                      <TableSizeDropdown setSize={setLearnerSize} setPage={setLearnerPage}/>
                                      <div className="w-form-done">
                                          <div>Thank you! Your submission has been received!</div>
                                      </div>
                                      <div className="w-form-fail">
                                          <div>Oops! Something went wrong while submitting the form.</div>
                                      </div>
                                  </div>
                                  <div className="sm">entries</div>
                              </div>
                              <div className="div-block-171">
                                  <div className="sm">Search:</div>
                                  <div className="form-block w-form">
                                      <form id="email-form-2" name="email-form-2" data-name="Email Form 2" method="get"
                                            data-wf-page-id="66e1c2afd7d305ab933f7a97"
                                            data-wf-element-id="4386085a-1eef-af03-c6b1-7a5a39f298f9">
                                          <input
                                              className="input w-input"
                                              data-name="Field 3"
                                              id="field-3"
                                              maxLength={256}
                                              name="field-3"
                                              placeholder=""
                                              required=""
                                              type="text"
                                              value={learnerSearch}
                                              onChange={(e) => {
                                                  setLearnerPage(1)
                                                  setLearnerSearch(e?.target?.value);
                                              }}/>
                                      </form>
                                      <div className="w-form-done">
                                          <div>Thank you! Your submission has been received!</div>
                                      </div>
                                      <div className="w-form-fail">
                                          <div>Oops! Something went wrong while submitting the form.</div>
                                      </div>
                                  </div>
                              </div>
                          </div>
                          <div react-component="table" className="table">
                              <div className="th">
                                  <div className="tc _10">
                                      <div className="sm">#</div>
                                  </div>
                                  <div className="tc _30">
                                      <div className="w_sort">
                                          <div className="text-block-134 fa_icon sort"></div>
                                          <div className="sm">Learner</div>
                                      </div>
                                  </div>
                                  <div className="tc _30">
                                      <div className="w_sort">
                                          <div className="text-block-134 fa_icon sort"></div>
                                          <div className="sm">Assigned {contentType}</div>
                                      </div>
                                  </div>
                                  <div className="tc _20">
                                      <div className="w_sort">
                                          <div className="text-block-134 fa_icon sort"></div>
                                          <div className="sm">Created at</div>
                                      </div>
                                  </div>
                              </div>
                              {
                                  learnersLoading ?
                                      <IBLSpinLoader containerHeight={"200px"} size={20} color={"white"}/>
                                      :
                                      learners.length > 0 ?
                                          learners.map((learner, index) => (
                                              <div className="tr">
                                                  <div className="tc tcell _10">
                                                      <div
                                                          className="m">{getTableIndex(index, learnerPage, learnerSize)}</div>
                                                  </div>
                                                  <div data-w-id="4386085a-1eef-af03-c6b1-7a5a39f2991c"
                                                       className="tc tcell _30"><img
                                                      src="/images/female20091023302387177.jpeg" loading="lazy" alt
                                                      className="r-i hidden"/>
                                                      <div
                                                          className="tl-2 np">{learner?.name}</div>
                                                  </div>
                                                  <div data-w-id="4386085a-1eef-af03-c6b1-7a5a39f2991c"
                                                       className="tc tcell _30"><img
                                                      src="/images/female20091023302387177.jpeg" loading="lazy" alt
                                                      className="r-i hidden"/>
                                                      <div
                                                          className="tl-2 np">{getContentName(learner)}</div>
                                                  </div>
                                                  <div className="tc tcell _20">
                                                      <div
                                                          className="m small">{learner?.created ? dayjs(learner?.created).format('DD MMM YYYY') : "-"}</div>
                                                  </div>
                                                  {/*<div className="tc tcell _10">
                                                      <SlTooltip>
                                                          <div style={{textAlign: "center"}} slot="content">
                                                              <span>View all {contentType}s</span>
                                                          </div>
                                                          <Link
                                                              to={`/teams/assignments/content/${learner?.id}`}
                                                              className="w-inline-block"><img src="/images/eye.svg"
                                                                                              loading="lazy" alt
                                                                                              className="image-154"/></Link>
                                                      </SlTooltip>
                                                  </div>*/}
                                                  <div onClick={() => handleAssignmentDeletion(learner)}
                                                       className="tc tcell _10">
                                                      <SlTooltip>
                                                          <div style={{textAlign: "center"}} slot="content">
                                                              <span>Delete assignment</span>
                                                          </div>
                                                          <div>
                                                              {focusedLearnerForDeletion === learner?.id ?
                                                                  (
                                                                      <IBLSpinLoader size={20} color={"#FFF"}/>
                                                                  )
                                                                  : (
                                                                      <img
                                                                          src="/images/trash-2.svg" loading="lazy" alt
                                                                          className="image-154"/>
                                                                  )
                                                              }
                                                          </div>
                                                      </SlTooltip>
                                                  </div>
                                              </div>
                                          ))
                                          :
                                          <EmptyTableDataInfographic/>

                              }
                              <div className="tf pagination-container">
                                  <div className="div-block-289">
                                      <div className="info-3">
                                          {t('Showing')}{' '}
                                          {(learnerPage - 1) * learnerSize + 1}{' '}
                                          {t('to')}{' '}
                                          {Math.min(learnerPage * learnerSize, learnersPagination.totalItems)}{' '}
                                          {t('of')} {learnersPagination.totalItems}
                                      </div>
                                      <ReactPaginate
                                          onPageChange={paginateLearners}
                                          pageCount={learnersPagination.totalPages}
                                          forcePage={learnerPage - 1}
                                          previousLabel={'Prev'}
                                          nextLabel={'Next'}
                                          containerClassName={'pagination'}
                                          pageLinkClassName={'page-number'}
                                          previousLinkClassName={'page-number'}
                                          nextLinkClassName={'page-number'}
                                          activeLinkClassName={'active'}
                                      />
                                  </div>
                              </div>
                          </div>
                      </div>
                  </div>
                  <div react-component="CourseTable" className="table_cont-2">
                      <div className="div-block-217">
                          <div className="div-block-169">
                              <div>
                                  <div prop-data-value-tablename="Courses" prop-data-names="tableName"
                                       className="text-block-135">Groups Assignments<br/></div>
                              </div>
                          </div>
                          <div className="div-block-169">
                              <div className="div-block-171">
                                  <div className="sm">Show</div>
                                  <div className="form-block w-form">
                                      <TableSizeDropdown setSize={setGroupSize} setPage={setGroupPage}/>
                                      <div className="w-form-done">
                                          <div>Thank you! Your submission has been received!</div>
                                      </div>
                                      <div className="w-form-fail">
                                          <div>Oops! Something went wrong while submitting the form.</div>
                                      </div>
                                  </div>
                                  <div className="sm">entries</div>
                              </div>
                              <div className="div-block-171">
                                  <div className="sm">Search:</div>
                                  <div className="form-block w-form">
                                      <form id="email-form-2" name="email-form-2" data-name="Email Form 2" method="get"
                                            data-wf-page-id="66e1c2afd7d305ab933f7a97"
                                            data-wf-element-id="4386085a-1eef-af03-c6b1-7a5a39f298f9">
                                          <input
                                              className="input w-input"
                                              data-name="Field 3"
                                              id="field-3"
                                              maxLength={256}
                                              name="field-3"
                                              placeholder=""
                                              required=""
                                              type="text"
                                              value={groupSearch}
                                              onChange={(e) => {
                                                  setGroupPage(1)
                                                  setGroupSearch(e?.target?.value);
                                              }}/>
                                      </form>
                                      <div className="w-form-done">
                                          <div>Thank you! Your submission has been received!</div>
                                      </div>
                                      <div className="w-form-fail">
                                          <div>Oops! Something went wrong while submitting the form.</div>
                                      </div>
                                  </div>
                              </div>
                          </div>
                          <div react-component="table" className="table">
                              <div className="th">
                                  <div className="tc _10">
                                      <div className="sm">#</div>
                                  </div>
                                  <div className="tc _30">
                                      <div className="w_sort">
                                          <div className="text-block-134 fa_icon sort"></div>
                                          <div className="sm">Group</div>
                                      </div>
                                  </div>
                                  <div className="tc _30">
                                      <div className="w_sort">
                                          <div className="text-block-134 fa_icon sort"></div>
                                          <div className="sm">Assigned {contentType}</div>
                                      </div>
                                  </div>
                                  <div className="tc _20">
                                      <div className="w_sort">
                                          <div className="text-block-134 fa_icon sort"></div>
                                          <div className="sm">Created at</div>
                                      </div>
                                  </div>
                              </div>
                              {
                                  groupsLoading ?
                                      <IBLSpinLoader containerHeight={"200px"} size={20} color={"white"}/>
                                      :
                                      groups.length > 0 ?
                                          groups.map((learner, index) => (
                                              <div className="tr">
                                                  <div className="tc tcell _10">
                                                      <div
                                                          className="m">{getTableIndex(index, learnerPage, learnerSize)}</div>
                                                  </div>
                                                  <div data-w-id="4386085a-1eef-af03-c6b1-7a5a39f2991c"
                                                       className="tc tcell _30"><img
                                                      src="/images/female20091023302387177.jpeg" loading="lazy" alt
                                                      className="r-i hidden"/>
                                                      <div
                                                          className="tl-2 np">{learner?.group_name}</div>
                                                  </div>
                                                  <div data-w-id="4386085a-1eef-af03-c6b1-7a5a39f2991c"
                                                       className="tc tcell _30"><img
                                                      src="/images/female20091023302387177.jpeg" loading="lazy" alt
                                                      className="r-i hidden"/>
                                                      <div
                                                          className="tl-2 np">{getContentName(learner)}</div>
                                                  </div>
                                                  <div className="tc tcell _20">
                                                      <div
                                                          className="m small">{learner?.created ? dayjs(learner?.created).format('DD MMM YYYY') : "-"}</div>
                                                  </div>
                                                  {/*<div className="tc tcell _10">
                                                      <SlTooltip>
                                                          <div style={{textAlign: "center"}} slot="content">
                                                              <span>View all {contentType}s</span>
                                                          </div>
                                                          <Link
                                                              to={`/teams/assignments/content/${learner?.id}`}
                                                              className="w-inline-block"><img src="/images/eye.svg"
                                                                                              loading="lazy" alt
                                                                                              className="image-154"/></Link>
                                                      </SlTooltip>
                                                  </div>*/}
                                                  <div onClick={() => handleAssignmentDeletion(learner, true)}
                                                       className="tc tcell _10">
                                                      <SlTooltip>
                                                          <div style={{textAlign: "center"}} slot="content">
                                                              <span>Delete assignment</span>
                                                          </div>
                                                          <div>
                                                              {focusedGroupForDeletion === learner?.id ?
                                                                  (
                                                                      <IBLSpinLoader size={20} color={"#FFF"}/>
                                                                  )
                                                                  : (
                                                                      <img
                                                                          src="/images/trash-2.svg" loading="lazy" alt
                                                                          className="image-154"/>
                                                                  )
                                                              }
                                                          </div>
                                                      </SlTooltip>
                                                  </div>
                                              </div>
                                          ))
                                          :
                                          <EmptyTableDataInfographic/>

                              }
                              <div className="tf pagination-container">
                                  <div className="div-block-289">
                                      <div className="info-3">
                                          {t('Showing')}{' '}
                                          {(groupPage - 1) * groupSize + 1}{' '}
                                          {t('to')}{' '}
                                          {Math.min(groupPage * groupSize, groupsPagination.totalItems)}{' '}
                                          {t('of')} {groupsPagination.totalItems}
                                      </div>
                                      <ReactPaginate
                                          onPageChange={paginateGroups}
                                          pageCount={groupsPagination.totalPages}
                                          forcePage={groupPage - 1}
                                          previousLabel={'Prev'}
                                          nextLabel={'Next'}
                                          containerClassName={'pagination'}
                                          pageLinkClassName={'page-number'}
                                          previousLinkClassName={'page-number'}
                                          nextLinkClassName={'page-number'}
                                          activeLinkClassName={'active'}
                                      />
                                  </div>
                              </div>
                          </div>
                      </div>
                  </div>
              </div>
          </div>
      </>
  );
};

export default TeamsAssignmentsListViewContent;
