import { getTenant } from '@iblai/ibl-web-react-common';

export const getTenantMetaData = (callback) => {
  const url =
    process.env.REACT_APP_IBL_DM_URL +
    `/api/core/orgs/${getTenant()}/metadata/`;
  fetch(url, {
    method: 'GET',
    //body: JSON.stringify(data),
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Token ' + localStorage.getItem('axd_token'),
    },
  })
    .then((resp) => {
      resp.json().then(callback).catch(callback);
    })
    .catch(callback);
};

export const updateTenantMetaData = (metadata, callback) => {
  const url =
    process.env.REACT_APP_IBL_DM_URL +
    `/api/core/orgs/${getTenant()}/metadata/`;
  fetch(url, {
    method: 'PUT',
    body: JSON.stringify({
      metadata,
    }),
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Token ' + localStorage.getItem('axd_token'),
    },
  })
    .then((resp) => {
      callback(resp.ok);
    })
    .catch(callback);
};

export const getPlatformDomainStatus = (platformKey, callback) => {
  const url =
    process.env.REACT_APP_IBL_LMS_URL +
    `/api/ibl/providers/aws/domain-status/?platform_key=${platformKey}`;
  fetch(url, {
    method: 'GET',
    credentials: 'include',
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'JWT ' + localStorage.getItem('edx_jwt_token'),
    },
  })
    .then(async (resp) => {
      callback(await resp.json());
    })
    .catch(callback);
};
