import {
  createBrowserRouter,
  isRouteErrorResponse,
  useRouteError,
} from 'react-router-dom';
import DownloadsView, {
  InvitationsView,
  ActivityView,
  CoursesView,
  CreatePathwayView,
  CredentialsView,
  CredentialView,
  IndexView,
  LearnersView,
  PathwaysView,
  PathwayView,
  ProgramsView,
  ProgramView,
  PublicProfileView,
  RegisteredUsersView,
  SkillsView,
} from './views';
import Error403 from './errors/pages/error403';
import Error404 from './errors/pages/error404';

import {
  isJSON,
  Logout,
  SsoLogin,
  getUserDataFromCookie,
  CompleteLogin,
  sentryCreateBrowserRouter,
  api,
  getUserName,
  getUserAdminCapabilities,
} from '@iblai/ibl-web-react-common';
import ActiveUsersView from './views/ActiveUsersView';
import EnrollmentsView from './views/EnrollmentsView';
import UnenrollmentsView from './views/UnenrollmentsView';
import CourseCompletionView from './views/CourseCompletionView';
import TimeSpentView from './views/TimeSpentView';
import GradingView from './views/GradingView';
import DashboardView from './views/DashboardView/DashboardView';
import ProfileView from './views/ProfileView';
import CredentialsListView from './views/CredentialsListView';
import StudioAuthoringCoursesView from './views/StudioAuthoringCoursesView';
import StudioAuthoringCourseAboutView from './views/StudioAuthoringCourseAboutView';
import StudioAuthoringCourseInstructorView from './views/StudioAuthoringCourseInstructorView';
import StudioAuthoringProgramAboutView from './views/StudioAuthoringProgramAboutView';
import StudioAuthoringProgramsView from './views/StudioAuthoringProgramsView';
import StudioAuthoringSettingsView from './views/StudioAuthoringSettingsView';
import LicensingContentView from './views/LicensingContentView';
import LicensingUserGroupsView from './views/LicensingUserGroupsView';
import UserLicensesView from './views/UserLicensesView';
import ProgramLicensesView from './views/ProgramLicensesView';
import AssertionsViewContent from './views/content/AssertionsViewContent/AssertionsViewContent';
import StudioAuthoringPathwaysViewContent from './views/content/StudioAuthoringPathwaysViewContent/StudioAuthoringPathwaysViewContent';
import StudioAuthoringCourseCreationView from './views/StudioAuthoringCourseCreationView';
import TeamsListView from './views/TeamsListView';
import TeamsAddNewView from './views/TeamsAddNewView';
import LearnersUnderTeamViewContent from './views/content/LearnersUnderTeamViewContent/LearnersUnderTeamViewContent';
import TeamsAddNewViewContent from './views/content/TeamsAddNewViewContent/TeamsAddNewViewContent';
import React from 'react';
import TeamsAssignmentCourseCreationViewContent from './views/content/TeamsAssignmentCourseCreationViewContent/TeamsAssignmentCourseCreationViewContent';
import TeamsAssignmentCreationParentViewContent from './views/content/TeamsAssignmentCreationParentViewContent/TeamsAssignmentCreationParentViewContent';
import TeamsAssignmentProgramCreationViewContent from './views/content/TeamsAssignmentProgramCreationViewContent/TeamsAssignmentProgramCreationViewContent';
import TeamsAssignmentPathwaysCreationViewContent from './views/content/TeamsAssignmentPathwaysCreationViewContent/TeamsAssignmentPathwaysCreationViewContent';
import TeamsAssignmentsListViewContent from './views/content/TeamsAssignmentsListViewContent/TeamsAssignmentsListViewContent';
import TeamsReportForLearnersViewContent from './views/content/TeamsReportForLearnersViewContent/TeamsReportForLearnersViewContent';
import TeamsReportForTeamsViewContent from './views/content/TeamsReportForTeamsViewContent/TeamsReportForTeamsViewContent';
import TeamsAssignmentSkillsCreationViewContent from './views/content/TeamsAssignmentSkillsCreationViewContent/TeamsAssignmentSkillsCreationViewContent';
import TeamsSkillsAssignmentsListViewContent from './views/content/TeamsSkillsAssignmentsListViewContent/TeamsSkillsAssignmentsListViewContent';
import TeamsNotificationEventViewContent from './views/content/TeamsNotificationEventViewContent/TeamsNotificationEventViewContent';
import TeamsManagersAssignedGroupsListViewContent from './views/content/TeamsManagersAssignedGroupsListViewContent/TeamsManagersAssignedGroupsListViewContent';
import TeamsAssignmentCredentialsCreationViewContent from './views/content/TeamsAssignmentCredentialsCreationViewContent/TeamsAssignmentCredentialsCreationViewContent';

const authCheckFn = (
  shouldBeAdmin,
  shouldBeLoggedIn,
  appData,
  setAppData,
  shouldBeDepartmentManager = false,
  redirect = ''
) => {
  return async () => {
    if (!shouldBeLoggedIn) {
      return [];
    }
    const userDataFromCookie = getUserDataFromCookie();
    if (shouldBeLoggedIn && (!appData?.axd_token || !appData?.dm_token)) {
      throw new Response(JSON.stringify({ shouldBeLoggedIn }), {
        status: 401,
      });
    }
    const adminCheckInLocalstorage = localStorage.getItem('userAdminCheck');
    let userAdminCheck = isJSON(adminCheckInLocalstorage)
      ? JSON.parse(adminCheckInLocalstorage)
      : false;
    if (!userAdminCheck) {
      userAdminCheck = await getUserAdminCapabilities();
    }
    //REDUCING REDUNDANT ENDPOINT CALL
    localStorage.setItem('userAdminCheck', JSON.stringify(userAdminCheck));
    if (!userAdminCheck) {
      throw new Response(
        JSON.stringify({
          redirect: '/logout',
        }),
        {
          status: 403,
        }
      );
    }
    if (
      !userAdminCheck?.is_department_admin &&
      !userAdminCheck?.is_platform_admin
    ) {
      throw new Response(
        JSON.stringify({
          redirect: '/logout',
        }),
        {
          status: 403,
        }
      );
    }
    if (shouldBeDepartmentManager && !userAdminCheck?.is_department_admin) {
      throw new Response(
        JSON.stringify({
          redirect: '/logout',
        }),
        {
          status: 403,
        }
      );
    }
    if (shouldBeAdmin && redirect && !userAdminCheck?.is_platform_admin) {
      window.location.href = redirect;
    }
    if (shouldBeAdmin && !userAdminCheck?.is_platform_admin) {
      throw new Response(
        JSON.stringify({
          redirect: '/',
        }),
        {
          status: 403,
        }
      );
    }
    return new Promise((resolve, reject) => {
      const getUserMetadataPromise = new Promise((_resolve, _reject) => {
        if (
          !Object.keys(JSON.parse(localStorage.userMetaData || '{}')).length
        ) {
          api.ibledxusers.getUsersManageMetadata(
            { username: getUserName() },
            function (data) {
              _resolve({ userMetaData: { ...data }, userMetaDataLoaded: true });
            },
            () => {
              _reject();
            }
          );
        } else {
          _resolve({});
        }
      });
      const getTenantsPromise = new Promise((_resolve, _reject) => {
        if (!localStorage.getItem('tenants')) {
          api.ibledxtenants.getUserTenants((tenants) => {
            const selectedTenant = localStorage.getItem('tenant');
            api.iblutils.saveUserTenantsDataToLocalStorage(
              tenants,
              selectedTenant
            );
            _resolve({
              current_tenant: localStorage.getItem('current_tenant'),
              tenants: localStorage.getItem('tenants'),
            });
          });
        } else {
          _resolve({});
        }
      });

      Promise.all([getUserMetadataPromise, getTenantsPromise])
        .then(async ([_userMetaDataInfo, _tenantInfo]) => {
          if (
            Object.keys(_userMetaDataInfo).length ||
            Object.keys(_tenantInfo).length
          ) {
            localStorage.setItem(
              'userMetaData',
              JSON.stringify({ ..._userMetaDataInfo })
            );
            setAppData({ ...appData, ..._userMetaDataInfo, ..._tenantInfo });
          }
          let domain = '';
          try {
            domain = await api.ibldmplatform.getPlatformCustomDomain(
              'analytics',
              localStorage.getItem('tenant')
            );
          } catch (error) {
            console.error('Error fetching custom domain:', error);
          }
          if (domain && domain !== window.location.hostname) {
            window.location.href = `https://${domain}`;
          }
          resolve([]);
        })
        .catch((error) => {
          reject('Failed to fetch User tenants / Metadata');
        });
    });
  };
};

const ErrorPage = () => {
  const error = useRouteError();

  if (isRouteErrorResponse(error)) {
    if (error.status === 404) {
      return <Error404 />;
    } else if (error.status === 403) {
      const data = isJSON(error?.data) ? JSON.parse(error.data) : {};
      return <Error403 redirect={data?.redirect ?? '/'} />;
    } else if (error.status === 401) {
      if (JSON.parse(error.data)?.shouldBeLoggedIn) {
        if (process.env.REACT_APP_IBL_IDP_URL) {
          window.location.href = process.env.REACT_APP_IBL_IDP_URL;
        } else {
          window.location.href = `${process.env.REACT_APP_IBL_SPA_AUTH_URL}/login?redirect-to=${window.location.origin}`;
        }
      }
    }
  }
};

const route = (appData, setAppData) => {
  return sentryCreateBrowserRouter([
    {
      path: '/',
      errorElement: <ErrorPage />,
      children: [
        {
          path: '',
          element: <DashboardView />,
          loader: authCheckFn(false, true, appData, setAppData, true),
          children: [
            {
              path: '',
              element: <IndexView />,
              loader: authCheckFn(
                true,
                true,
                appData,
                setAppData,
                false,
                '/teams/report/learners'
              ),
            },
            {
              path: 'per-learner/learners',
              element: <LearnersView />,
              loader: authCheckFn(true, true, appData, setAppData),
            },
            {
              path: 'per-learner/learners/:username',
              element: <ProfileView />,
              loader: authCheckFn(true, true, appData, setAppData),
              children: [
                {
                  path: 'activity',
                  element: <ActivityView />,
                },
                {
                  path: 'credentials',
                  element: <CredentialsView />,
                },
                {
                  path: ':credentials/:credentialID',
                  element: <CredentialView />,
                },
                {
                  path: 'pathways/:pathwayID',
                  element: <PathwayView />,
                },
                {
                  path: 'pathways/new',
                  element: <CreatePathwayView />,
                },
                {
                  path: 'skills',
                  element: <SkillsView />,
                },
                {
                  path: 'pathways',
                  element: <PathwaysView />,
                },
                {
                  path: 'courses',
                  element: <CoursesView />,
                },
                {
                  path: 'programs',
                  element: <ProgramsView />,
                },
                {
                  path: 'programs/:programID',
                  element: <ProgramView />,
                },
                {
                  path: 'public-profile',
                  element: <PublicProfileView />,
                },
              ],
            },
            {
              path: 'audience/registered-users',
              element: <RegisteredUsersView />,
              loader: authCheckFn(true, true, appData, setAppData),
            },
            {
              path: 'audience/active-users',
              element: <ActiveUsersView />,
              loader: authCheckFn(true, true, appData, setAppData),
            },
            {
              path: 'audience/enrollments',
              element: <EnrollmentsView />,
              loader: authCheckFn(true, true, appData, setAppData),
            },
            {
              path: 'licensing/platforms',
              element: <LicensingContentView />,
              loader: authCheckFn(true, true, appData, setAppData),
            },
            {
              path: 'licensing/users',
              element: <UserLicensesView />,
              loader: authCheckFn(true, true, appData, setAppData),
            },
            {
              path: 'licensing/content',
              element: <ProgramLicensesView />,
              loader: authCheckFn(true, true, appData, setAppData),
            },
            {
              path: 'teams/list',
              element: <TeamsListView />,
              loader: authCheckFn(true, true, appData, setAppData),
            },
            {
              path: 'teams/management',
              element: <TeamsManagersAssignedGroupsListViewContent />,
              loader: authCheckFn(true, true, appData, setAppData),
            },

            {
              path: 'teams/list/:teamID',
              element: <LearnersUnderTeamViewContent />,
              loader: authCheckFn(true, true, appData, setAppData),
            },
            {
              path: 'teams/update/:teamID',
              element: <TeamsAddNewViewContent />,
              loader: authCheckFn(true, true, appData, setAppData),
            },
            {
              path: 'teams/report',
              element: <TeamsReportForTeamsViewContent />,
            },
            {
              path: 'teams/report/learners',
              element: <TeamsReportForLearnersViewContent />,
            },
            {
              path: 'teams/notifications/add-new',
              element: <TeamsNotificationEventViewContent />,
            },

            {
              path: 'teams/add-new',
              element: <TeamsAddNewViewContent />,
              loader: authCheckFn(true, true, appData, setAppData),
            },
            //ASSIGNMENTS VIEWS
            {
              path: 'teams/assignments/courses',
              element: (
                <TeamsAssignmentsListViewContent contentType={'course'} />
              ),
            },
            {
              path: 'teams/assignments/programs',
              element: (
                <TeamsAssignmentsListViewContent contentType={'program'} />
              ),
            },
            {
              path: 'teams/assignments/pathways',
              element: (
                <TeamsAssignmentsListViewContent contentType={'pathway'} />
              ),
            },
            {
              path: 'teams/assignments/credentials',
              element: (
                <TeamsAssignmentsListViewContent contentType={'credential'} />
              ),
            },
            {
              path: 'teams/assignments/skills',
              element: <TeamsSkillsAssignmentsListViewContent />,
            },
            //ASSIGNMENTS CREATIONS
            {
              path: 'teams/assignments/add-new',
              element: <TeamsAssignmentCreationParentViewContent />,
              children: [
                {
                  path: '',
                  element: <TeamsAssignmentCourseCreationViewContent />,
                },
                {
                  path: 'courses',
                  element: <TeamsAssignmentCourseCreationViewContent />,
                },
                {
                  path: 'programs',
                  element: <TeamsAssignmentProgramCreationViewContent />,
                },
                {
                  path: 'pathways',
                  element: <TeamsAssignmentPathwaysCreationViewContent />,
                },
                {
                  path: 'skills',
                  element: <TeamsAssignmentSkillsCreationViewContent />,
                },
                {
                  path: 'credentials',
                  element: <TeamsAssignmentCredentialsCreationViewContent />,
                },
              ],
            },
            {
              path: 'licensing/groups',
              element: <LicensingUserGroupsView />,
              loader: authCheckFn(true, true, appData, setAppData),
            },
            {
              path: 'audience/unenrollments',
              element: <UnenrollmentsView />,
              loader: authCheckFn(true, true, appData, setAppData),
            },
            {
              path: 'engagement/course-completion',
              element: <CourseCompletionView />,
              loader: authCheckFn(true, true, appData, setAppData),
            },
            {
              path: 'engagement/time-spent',
              element: <TimeSpentView />,
              loader: authCheckFn(true, true, appData, setAppData),
            },
            {
              path: 'performance/grading',
              element: <GradingView />,
              loader: authCheckFn(true, true, appData, setAppData),
            },
            {
              path: 'performance/credentials',
              element: <CredentialsListView />,
              loader: authCheckFn(true, true, appData, setAppData),
            },
            {
              path: 'studio/authoring/courses',
              element: <StudioAuthoringCoursesView />,
              loader: authCheckFn(true, true, appData, setAppData),
            },
            {
              path: 'studio/authoring/courses/add',
              element: <StudioAuthoringCourseCreationView />,
              loader: authCheckFn(true, true, appData, setAppData),
            },
            {
              path: 'studio/authoring/courses/:courseID',
              element: <StudioAuthoringCourseAboutView />,
              loader: authCheckFn(true, true, appData, setAppData),
            },
            {
              path: 'studio/authoring/assertions/:courseID/:entityID',
              element: <AssertionsViewContent />,
              loader: authCheckFn(true, true, appData, setAppData),
            },
            {
              path: 'studio/authoring/courses/:courseID/instructor',
              element: <StudioAuthoringCourseInstructorView />,
              loader: authCheckFn(true, true, appData, setAppData),
            },
            {
              path: 'studio/authoring/programs',
              element: <StudioAuthoringProgramsView />,
              loader: authCheckFn(true, true, appData, setAppData),
            },
            {
              path: 'studio/authoring/programs/:programID',
              element: <StudioAuthoringProgramAboutView />,
              loader: authCheckFn(true, true, appData, setAppData),
            },
            {
              path: 'studio/authoring/pathways',
              element: <StudioAuthoringPathwaysViewContent />,
              loader: authCheckFn(true, true, appData, setAppData),
            },
            {
              path: 'studio/settings',
              element: <StudioAuthoringSettingsView />,
              loader: authCheckFn(true, true, appData, setAppData),
            },

            {
              path: 'downloads',
              element: <DownloadsView />,
            },
            {
              path: 'invites',
              element: <InvitationsView />,
              loader: authCheckFn(true, true, appData, setAppData),
            },
          ],
        },
        {
          path: 'login/complete',
          element: <CompleteLogin />,
        },
        {
          path: 'sso-login',
          element: <SsoLogin />,
          loader: authCheckFn(false, false, appData, setAppData),
        },
        {
          path: 'logout',
          element: (
            <Logout
              redirectTo={`${process.env.REACT_APP_IBL_SPA_AUTH_URL}/logout?redirect-to=${window.location.origin}`}
            />
          ),
          loader: authCheckFn(false, false, appData, setAppData),
        },
      ],
    },
  ]);
};
export default route;
