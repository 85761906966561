import React, {useContext, useEffect, useState} from 'react';
import { useTour } from '@reactour/tour'
import {api, AppDataContext, getUserMetadataAPI, getUserName} from "@iblai/ibl-web-react-common";
import {isCurrentUserOnlyDepartmentManager} from "./helpers";

const useTourTooltips = () => {

    const { isOpen, beforeClose,
        currentStep, steps, setIsOpen, setCurrentStep, setSteps } = useTour()

    const {appData} = useContext(AppDataContext)

    const [tourTooltipStarted, setTourTooltipStarted] = useState(false)
    const [userHasPreviouslyLoadedTourTooltips, setUserHasPreviouslyLoadedTourTooltips] = useState(true)

    const platformAdminsTours = [
        {
            selector: '.tour-tooltip-step-1',
            content: 'Get a bird’s-eye view of key performance metrics, such as learner engagement, platform activity, and completion rates. Click through to explore specific data points.',
            position:"right",
        },
        {
            selector: '.tour-tooltip-step-2',
            content: 'Monitor how much time learners engage with content and how they are progressing in their courses. Use these metrics to gauge learner engagement and identify areas for improvement.',
            position:"right",
        },
        {
            selector: '.tour-tooltip-step-3',
            content: 'Evaluate learner grades and credentials with ease. Measure success rates and identify top performers, all in one place.',
            position:"right"
        },
        {
            selector: '.tour-tooltip-step-4',
            content: 'Access individual learner performance data, including time spent, courses completed, and skills acquired. Use this to keep track of where your learners are at and offer personalized learning recommendations.',
            position:"right"
        },
        {
            selector: '.tour-tooltip-step-5',
            content: 'Centralize your learner management here. Easily assign users to groups for efficient content distribution, team assignments, and license management with simple, intuitive controls.',
            position:"right"
        },
        {
            selector: '.tour-tooltip-step-6',
            content: 'Generate detailed reports on user activity, course completion, enrollment, and more. Use these reports to make data-driven decisions and improve platform efficiency.',
            position:"bottom"
        },
        {
            selector: '.tour-tooltip-step-7',
            content: 'Manage all licensing in one place. Assign user and content licenses to ensure seamless access, and track your platform’s overall license usage.',
            position:"right"
        },
        {
            selector: '.tour-tooltip-step-8',
            content: 'Build courses, pathways, and programs for learners. Assign users to content, edit materials, issue credentials, and allocate skill points—all in one centralized hub for content creation and management.',
            position:"right"
        },
        {
            selector: '.tour-tooltip-step-9',
            content: 'Assign courses, credentials, and skills to specific teams, send notifications, and track learner progress—all within a structured, role-based system.',
            position:"right"
        },


    ]

    const handleSidebarTooltipsMenuUncollapse = () => {
        // Select all elements with class starting with "tour-tooltip-step-"
        const tooltipSteps = document.querySelectorAll('[class*="tour-tooltip-step-"]')
        // Loop through each tooltip step
        tooltipSteps.forEach((step) => {
            // Find the child element with the class "submenu"
            const submenu = step.querySelector('.submenu');

            // Add the "clicked" class to the submenu if it exists
            if (submenu) {
                submenu.classList.add('clicked');
            }
        });
    }

    const handleSidebarTooltipsMenuCollapse = () => {
        // Select all elements with class starting with "tour-tooltip-step-"
        const tooltipSteps = document.querySelectorAll('[class*="tour-tooltip-step-"]')
        // Loop through each tooltip step
        tooltipSteps.forEach((step) => {
            // Find the child element with the class "submenu"
            const submenu = step.querySelector('.submenu');

            // Add the "clicked" class to the submenu if it exists
            if (submenu) {
                submenu.classList.remove('clicked');
            }
        });
    }

    useEffect(() => {
        if(typeof appData.userMetaData?.public_metadata?.tour_tooltips?.loaded_on_analytics_spa === "boolean"){
            setUserHasPreviouslyLoadedTourTooltips(appData.userMetaData?.public_metadata?.tour_tooltips?.loaded_on_analytics_spa)
        }else{
            getUserMetadataAPI(getUserName(), (metadata)=>{
               const hasBeenPreviouslyLoaded = metadata?.public_metadata?.tour_tooltips?.loaded_on_analytics_spa===true
                setUserHasPreviouslyLoadedTourTooltips(hasBeenPreviouslyLoaded)
            })
        }
    }, [appData?.userMetaData?.public_metadata]);

    const handleUpdateTourTooltipsStatusOnUserMetaData = () => {
        const updatedPublicMetaData = {
            ...appData?.userMetaData?.public_metadata,
            tour_tooltips:{
                ...appData?.userMetaData?.public_metadata?.tour_tooltips,
                loaded_on_analytics_spa:true
            }
        }
        const { bio, about, is_profile_public, social_links, profile_image, language } = appData?.userMetaData
        const updatedMetaData = {
            bio,
            about,
            is_profile_public,
            language,
            social_links,
        };
        const metaDataToSubmit = {
            public_metadata: {
                ...updatedPublicMetaData,
                ...updatedMetaData,
                profile_image,
            },
            ...updatedMetaData,
            username: getUserName()
        };
        api.ibledxusers.postUsersManageMetadata(
            JSON.stringify(metaDataToSubmit),
            (metadata)=>{
                setUserHasPreviouslyLoadedTourTooltips(true)
            })
    }

    const handleStartTour = () => {
        setSteps(platformAdminsTours)
        setIsOpen(true)
        setTourTooltipStarted(true)
    }

    useEffect(() => {
        if(!isOpen && tourTooltipStarted){
            handleUpdateTourTooltipsStatusOnUserMetaData()
        }
    }, [tourTooltipStarted, isOpen]);

    useEffect(()=>{
        //TODO handle department_admin tooltips
        if(!userHasPreviouslyLoadedTourTooltips && !isCurrentUserOnlyDepartmentManager()){
            handleStartTour()
        }
    }, [userHasPreviouslyLoadedTourTooltips])

    return {
        handleStartTour, platformAdminsTours, isOpen, currentStep, steps, setIsOpen, setCurrentStep, setSteps
    }
};

export default useTourTooltips;